p {
  font-family: "belly";
  font-weight: 500;
  color: #9E9E9E;
  margin-block: 0;
  font-size: 35px;
}

h1 {
  font-family: "belly";
  font-weight: 500;
  color: #2D2D2D;
  margin-block: 0;
  font-size: 60px;
  margin-bottom: 20px;
}

.main-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.name-container {
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.bottom-box {
  margin-bottom: 30px;
}

.language-box {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}
.language-name.active{
  color: #2D2D2D;
}